import { SchemaType } from "@/common/models/SchemaType";

/**
 * Inside CardResp
 */
export class ContractResp {
  public address = "";
  public contractStandard = "";
  public contractType: ContractType = ContractType.Issue;
  public name = "";
  public description = "";
  public image = "";
  public startOn = "";
  public endOn = "";
  public issueAmount?: number = undefined;
  public redeemAmount?: number = undefined;
  public isMemberTrigger = false;
  public isTriggerOnClick = false;
  public schema = SchemaType.None;
  public isVoucher = false;
  public voucherDesc?: string = undefined;
}

export enum ContractType {
  Issue = "Issue",
  Redeem = "Redeem",
  Voucher = "Voucher",
}
