import moment from "moment";
import { TransactionType } from "@/common/models";
import { TransactionResp } from "@/services/liff/models";

export default class TradeInfoPropDTO {
  public date = "";
  public data: TradeContent[] = [];
}

export class TradeContent {
  public id = "";
  public title = "";
  public amount = "";
  public type: TransactionType = TransactionType.Issue;
  public isRevert = false;
  public revertTime = "";
  public pointName = "";
  public commentSubtile = "";
  public timestamp = "";
  public description = "";
  public storeName = "";
  public note = "";
  public revertNote = "";
  public icon = IconType.In;

  public mapFromApiModel(model: TransactionResp) {
    this.timestamp = moment(model.transactionOn)
      .utc()
      .utcOffset(8)
      .format("HH:mm");
    this.title = `${model.tokenName}：${model.contractName}`;
    this.pointName = model.tokenName;
    this.description = model.contractName;
    this.storeName = model.accountName;
    this.type = model.transactionType;
    this.amount =
      this.type === TransactionType.Issue
        ? `+${model.amount}`
        : `-${model.amount}`;
    this.note = model.note ?? "";
    this.revertNote = model.revertNote ?? "";
    this.isRevert = model.isRevert;
    if (model.revertTransactionOn) {
      this.revertTime = moment(model.revertTransactionOn)
        .utc()
        .utcOffset(8)
        .format("yyyy/MM/DD HH:mm");
    }

    if (!this.isRevert) {
      switch (this.type) {
        case TransactionType.Issue:
          this.icon = this.revertTime === "" ? IconType.In : IconType.InPatch;
          break;
        case TransactionType.Redeem:
          this.icon = this.revertTime === "" ? IconType.Out : IconType.OutPatch;
          break;
        default:
          break;
      }
    } else {
      this.icon =
        this.type === TransactionType.Issue
          ? IconType.InPatch
          : IconType.OutPatch;
    }
  }
}

export enum IconType {
  In,
  Out,
  InPatch,
  OutPatch,
}
