export default {
  "text": {
    "phoneNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話號碼"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認送出"])},
    "confirmSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定儲存"])},
    "alreadySave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已儲存"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
    "confirm2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定"])},
    "merchantConfirming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商家確認中"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "cancelTrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消交易"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請稍候"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["略過"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉"])},
    "iKnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我知道了"])}
  },
  "common": {
    "pointUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點"])},
    "courseUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["堂"])},
    "amountUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個"])},
    "priceUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點數"])},
    "pointCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集點卡"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程"])},
    "courseCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程卡"])},
    "latestEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新活動"])},
    "lotteryEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抽獎活動"])},
    "howToGet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如何獲得"])},
    "howToRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如何兌換"])},
    "normalEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般活動"])},
    "onlineEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掃碼及線上領取"])},
    "noIssueEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前暫無集點活動"])},
    "inShopRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到店兌換"])},
    "onlineRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掃碼及線上兌換"])},
    "eTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子票券"])},
    "noRedeemEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前暫無兌換活動"])},
    "havePoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["擁有點數"])},
    "remainPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩餘點數"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領取"])},
    "getPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集點"])},
    "getPoint2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領取點數"])},
    "redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兌換"])},
    "redeem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扣點兌換"])},
    "scanQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掃碼"])},
    "redeemExplain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兌換說明"])},
    "eventExplain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動說明："])},
    "redeemEndOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兌換期限："])},
    "schema": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隨機點數"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依購買數量"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依消費金額"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依商品而定"])}
    ],
    "detailCourseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細課程資訊"])},
    "notSpecify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不指定"])},
    "contactReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["學員另洽預約"])},
    "totalCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程堂數"])},
    "remainCourse": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["剩餘堂數：", _interpolate(_named("remain"))])},
    "remainCourse2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩餘堂數"])},
    "remainDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩餘天數"])},
    "coursePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程卡效期"])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已過期"])},
    "courseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上課時間"])},
    "courseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程內容"])},
    "courseExplain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程卡使用說明"])},
    "courseDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*本課程卡僅供紀錄使用，給優點平台不負責內容之履行，內容為課程老師與學生之間之義務。"])}
  },
  "bind": {
    "bind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["綁定"])},
    "alreadyBind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已綁定"])},
    "alreadyBindAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該帳戶已完成綁定程序"])},
    "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證碼"])},
    "sendOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發送驗證碼"])},
    "resendOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新發送"])},
    "otpValidTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證碼有效時間"])},
    "bindMemberPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["綁定會員電話"])},
    "phoneExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入電話：0987654321"])},
    "unclearCaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看不清楚？"])},
    "wrongCaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證碼輸入錯誤"])},
    "notReceiveOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有收到驗證碼？"])},
    "memberEula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員條款"])},
    "svcProviderCyberSaaS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平台提供者：CyberSaaS給優點"])},
    "agreeEula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我已詳閱並同意會員條款（請將條款拉到最底，始能完成同意）"])},
    "bindSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["綁定成功"])}
  },
  "validate": {
    "requireField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必填欄位"])},
    "pleaseInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入"])},
    "pleaseInputValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入合法的"])},
    "buyAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購買數量"])},
    "buyPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購買金額"])},
    "prompt": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["・請確實輸入正確的購買數量"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["・輸入完成請點選「下一步」，以繼續進行拿點活動"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["・請確實輸入正確的付款金額"])}
    ]
  },
  "member": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員資料"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手機"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
    "reminder": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提醒您"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一旦填寫生日日期，完成儲存後，日後將無法進行修改，請確認是否填寫正確。"])}
    ]
  },
  "level": {
    "levelRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員升降級及續會條件："])},
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["【", _interpolate(_named("title")), "】"])},
    "accumulate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再累積"])},
    "willRenewTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可續會為"])},
    "willUpgradeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將升級為"])},
    "joinRule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["•入會條件：首次獲點立即成為", _interpolate(_named("level")), "，並享永久", _interpolate(_named("level")), "資格權益。"])},
    "firstUpgrade": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若首次獲點單筆點數達"])},
      (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["點，則直接跳級至", _interpolate(_named("level")), "。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以此類推。"])}
    ],
    "upgrade": [
      (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["•升級條件：成為", _interpolate(_named("level")), "後於會員效期內累積點數達"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點，或於較低等級時單筆獲得點數達"])},
      (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["點，即可立即升級為", _interpolate(_named("level")), "，並享有"])},
      (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("level")), "權益"])}
    ],
    "renew": [
      (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["•續會條件：", _interpolate(_named("level")), "於會員"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期效期內，累積點數達續會條件"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點，即可續會"])},
      (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["。若未達續會條件，則降級回", _interpolate(_named("level")), "。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。若未達續會條件，則依據會員所擁有之點數數量，往下判斷其符合哪一等級之續會條件，並將其納入該等級會員。"])}
    ],
    "notice": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意事項："])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 會員升降級與續會資格之點數統計至到期日為止，新的會員等級將於統計完成後的12小時內更新。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 符合續會資格之會員，續會起始日為當期會員到期日之次日起計。"])}
    ]
  },
  "history": {
    "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["近30天"])},
    "txHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易紀錄"])},
    "seeMoreInApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下載給優點APP查看更多"])},
    "origTxTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原始交易時間："])},
    "revertTxTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更正交易時間："])},
    "pointRevertNote": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["點數更正備註：", _interpolate(_named("note"))])},
    "courseRevertNote": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["課卡更正備註：", _interpolate(_named("note"))])}
  },
  "result": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗"])},
    "congratsGetPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恭喜完成集點！至我的收藏查看您的點數。"])},
    "congratsRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恭喜兌換成功！"])},
    "redeemAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新兌換"])},
    "checkNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請檢查您的網路連線狀態。"])},
    "svcError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系統異常，請稍後再試。"])},
    "notEnough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點數餘額不足。"])},
    "youWillGet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您將獲得"])},
    "youWillRedeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您將兌換"])},
    "youGot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已獲得"])},
    "getPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得點數"])},
    "tradeFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易失敗"])},
    "issueErrTitle": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易取消"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易失敗"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集點活動已結束"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已達領取上限"])}
    ],
    "issueErrMsg": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商家已取消此筆交易，請與商家聯絡，稍後再試。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商家目前忙碌中，請您稍後再試。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本次集點活動已結束，歡迎您下次及早參與活動。"])},
      (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["本次集點活動每人限領取 ", _interpolate(_named("max")), " 次，您已達領取上限。"])},
      (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["本次集點活動每人每天限領取 ", _interpolate(_named("max")), " 次，您已達領取上限。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本次集點活動點數已全數發放完畢，歡迎您下次及早參與活動。"])}
    ],
    "redeemErrTitle": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易取消"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易失敗"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動已結束"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已達兌換上限"])}
    ],
    "redeemErrMsg": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商家已取消此筆交易，請與商家聯絡，稍後再試。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商家目前忙碌中，請您稍後再試。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本次活動已結束，歡迎您下次及早參與活動。"])},
      (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["本次活動每人限兌換 ", _interpolate(_named("max")), " 次，您已達兌換上限。"])},
      (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["本次集點活動每人每天限兌換 ", _interpolate(_named("max")), " 次，您已達兌換上限。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本次集點活動點數已全數兌換完畢，歡迎您下次及早參與活動。"])}
    ]
  },
  "time": {
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個月"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
    "week": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期一"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期二"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期三"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期四"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期五"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期六"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期日"])}
    ],
    "format": {
      "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上午"])},
      "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下午"])}
    },
    "noEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無期限"])}
  },
  "gender": {
    "None": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男生"])},
    "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女生"])}
  }
}