import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { isLangSupport, setLanguage, supportedLangs } from "@/plugins/i18n";
import AccountService from "@/services/account.service";
import Cookies from 'quasar/src/plugins/Cookies.js';import useMeta from 'quasar/src/composables/use-meta.js';;
import liff from "@line/liff";

const routes: Array<RouteRecordRaw> = [
  {
    name: "primary_url",
    path: "/:liffId/:accountId",
    component: () => import("@/views/PrimaryURL.vue"),
  },
  {
    name: "bind",
    path: "/:liffId/:accountId/:lang/Bind",
    meta: {
      needLIFF: true,
    },
    component: () => import(/* webpackChunkName: "Bind" */ "@/views/Bind.vue"),
  },
  {
    name: "customerInfo",
    path: "/:liffId/:accountId/:lang/CustomerInfo",
    component: () =>
      import(/* webpackChunkName: "Bind" */ "@/views/MemberInfoCheck.vue"),
  },
  {
    name: "eula",
    path: "/:liffId/:accountId/:lang/Eula",
    component: () => import(/* webpackChunkName: "Bind" */ "@/views/Eula.vue"),
  },
  {
    name: "finalBind",
    path: "/:liffId/:accountId/:lang/Final",
    component: () =>
      import(/* webpackChunkName: "Bind" */ "@/views/FinalBind.vue"),
  },
  {
    name: "latest_event",
    path: "/:liffId/:accountId/:lang/Latest",
    meta: {
      needLIFF: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "Latest" */ "@/views/LatestEvent/LatestEvent.vue"
      ),
    children: [
      {
        name: "point_latest",
        path: "Event",
        component: () =>
          import(
            /* webpackChunkName: "Latest" */ "@/views/LatestEvent/PointOverview.vue"
          ),
      },
      {
        name: "course_latest",
        path: "Course",
        component: () =>
          import(
            /* webpackChunkName: "Latest" */ "@/views/LatestEvent/CourseOverview.vue"
          ),
      },
    ],
  },
  {
    name: "trade_history",
    path: "/:liffId/:accountId/:lang/History",
    meta: {
      needLIFF: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "History" */ "@/views/History/TradeHistory.vue"
      ),
    children: [
      {
        name: "point_history",
        path: "Point",
        component: () =>
          import(
            /* webpackChunkName: "History" */ "@/views/History/Point/Point.vue"
          ),
      },
      {
        name: "course_history",
        path: "Course",
        component: () =>
          import(
            /* webpackChunkName: "History" */ "@/views/History/Course/Course.vue"
          ),
      },
    ],
  },
  {
    name: "eticket_detail",
    path: "/:liffId/:accountId/:lang/Latest/ETicket",
    component: () =>
      import(
        /* webpackChunkName: "Latest" */ "@/views/LatestEvent/ETicketDetail.vue"
      ),
  },
  {
    name: "issue",
    path: "/:liffId/:accountId/:lang/Latest/Issue/:eventId/:id",
    component: () =>
      import(
        /* webpackChunkName: "Latest" */ "@/views/LatestEvent/function/Issue.vue"
      ),
  },
  {
    name: "issue_random",
    path: "/:liffId/:accountId/:lang/Latest/IssueRandom/:eventId/:id",
    component: () =>
      import(
        /* webpackChunkName: "Latest" */ "@/views/LatestEvent/function/IssueRandom.vue"
      ),
  },
  {
    name: "issue_amount",
    path: "/:liffId/:accountId/:lang/Latest/IssueAmount/:eventId/:id",
    component: () =>
      import(
        /* webpackChunkName: "Latest" */ "@/views/LatestEvent/function/IssueAmount.vue"
      ),
  },
  {
    name: "issue_price",
    path: "/:liffId/:accountId/:lang/Latest/IssuePrice/:eventId/:id",
    component: () =>
      import(
        /* webpackChunkName: "Latest" */ "@/views/LatestEvent/function/IssuePrice.vue"
      ),
  },
  {
    name: "redeem",
    path: "/:liffId/:accountId/:lang/Latest/Redeem/:type/:eventId/:id",
    component: () =>
      import(
        /* webpackChunkName: "Latest" */ "@/views/LatestEvent/function/Redeem.vue"
      ),
  },
  {
    name: "success_result",
    path: "/:liffId/:accountId/:lang/Success/:type",
    component: () =>
      import(/* webpackChunkName: "Latest" */ "@/views/Result/Success.vue"),
  },
  {
    name: "error_result",
    path: "/:liffId/:accountId/:lang/Error/:type",
    component: () =>
      import(/* webpackChunkName: "Latest" */ "@/views/Result/Error.vue"),
  },
  {
    name: "not_found_result",
    path: "/:lang/NotFound",
    component: () => import("@/views/Result/NotFound.vue"),
  },
  {
    name: "course_detail",
    meta: {
      needLIFF: true,
    },
    path: "/:liffId/:accountId/:lang/Course",
    component: () => import("@/views/CourseDetail.vue"),
  },
  {
    name: "member_level",
    meta: {
      needLIFF: true,
    },
    path: "/:liffId/:accountId/:lang/MemberLevel",
    component: () => import("@/views/MemberLevel.vue"),
  },
  {
    name: "customer_info_level",
    meta: {
      needLIFF: true,
    },
    path: "/:liffId/:accountId/:lang/MemberInfo",
    component: () => import("@/views/MemberInfo.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === "primary_url") {
    next();
    return true;
  }

  const lang: string = to.params.lang as string;
  if (!isLangSupport(lang)) {
    next(`/${supportedLangs[0]}/NotFound`);
    return true;
  }

  setLanguage(to.params.lang as string);
  const titleKey = `title/${to.params.accountId}`;
  if (!Cookies.has(titleKey)) {
    const accountService = new AccountService();
    const profile = await accountService.GetProfile(
      to.params.accountId as string
    );
    if (profile) {
      Cookies.set(titleKey, profile.name as string, {
        expires: "1h",
        secure: false,
        path: "/",
      });
    }
  }

  useMeta(() => {
    return { title: Cookies.get(titleKey) };
  });

  const { needLIFF } = to.meta;
  if (!needLIFF) {
    next();
    return true;
  }

  const liffId = to.params.liffId as string;
  await liff.init({ liffId });
  if (!liff.isInClient() && !liff.isLoggedIn()) {
    liff.login({ redirectUri: window.location.href });
    return false;
  }

  const idToken = liff.getIDToken();
  Cookies.set(`id_token/${to.params.accountId}`, idToken as string, {
    expires: "1h",
    secure: true,
    path: "/",
  });

  const profileKey = "line_profile";
  if (!Cookies.has(profileKey)) {
    const profile = await liff.getProfile();
    Cookies.set(profileKey, profile.pictureUrl as string, {
      expires: 1,
      secure: true,
      path: "/",
    });
  }
  next();
});

export default router;
